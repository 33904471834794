/** @module store/Upload */

import { types } from "mobx-state-tree";
import TreeNode from './TreeNode';
import { humanFileSize } from '../util';

/**
 * This model represents an uploading file. The properties are read-only.
 * 
 * @class Upload
 * @property {string} cid The cid
 * @property {string} name The name of the uploaded file
 * @property {module:store/TreeNode~TreeNode} node The destination tree node
 * @property {int} percent The percent
 * @property {int} loaded The loaded size
 * @property {int} size The total size of the upload
 * @property {string} [previewSrc] The src for the preview image
 * @property {React.Element|string} [deny] Deny message
 * @property {int} readableLoaded The loaded size in human readable format
 * @property {int} readableSize The total size of the upload in human readable format
 */
const Upload = types.model('RMLUpload', {
    cid: types.identifier(types.string),
    name: types.string,
    node: types.reference(TreeNode),
    percent: types.number, // Not computed because it comes directly from plupload
    loaded: types.number,
    size: types.number,
    previewSrc: types.optional(types.string),
    deny: types.optional(types.frozen)
}).views(self => ({
    get readableLoaded() {
        return humanFileSize(self.loaded);
    },
    
    get readableSize() {
        return humanFileSize(self.size);
    }
})).actions(self => ({
    /**
     * Update this upload attributes.
     * 
     * @param {function} callback The callback with one argument (node draft)
     * @memberof module:store/Upload~Upload
     * @instance
     */
    setter(callback) {
        callback(self);
    }
}));

export default Upload;