/** @module components/Breadcrumb */

import React from 'react';
import ReactDOM from 'react-dom';
import { ICON_OBJ_FOLDER_ROOT } from '../util';
import { Icon } from 'react-aiot';

const ICON_OBJ_SEP = <Icon type="right" />;

/**
 * Simple breadcrumbs with arrows and a home icon.
 * 
 * @property {string[]} path The pathes
 * @type React.Element
 */
export default ({ path }) => {
    let i = 0; // Use counter as key
    return <div>
        { ICON_OBJ_FOLDER_ROOT }&nbsp;&nbsp;
        { path.map(item => {
            return <span key={ i++ }>{ item }&nbsp;{ i < path.length && ICON_OBJ_SEP }&nbsp;</span>;
        }) }
    </div>;
};