/**
 * Create a WP RFC for the shortcut info container.
 * 
 * @property {string[]} data-path The pathes
 * @module others/rfcShortcutInfo
 */

import $ from 'jquery';
import hooks from '../util/hooks';
import { ajax } from '../util';

/**
 * Append HTML content below the attachment details.
 * 
 * @param {jQuery} container The container
 * @param {string} html The html
 * @returns {jQuery}
 */
const appendTo = (container, html) => {
    const attachmentDetails = container.parents('.attachment-details'),
        mediaSidebar = container.parents('.media-sidebar');
    
    // Check if it is already an container
    (mediaSidebar.length > 0 ? mediaSidebar :
    	(attachmentDetails.length > 0 ? attachmentDetails : container))
    	.find('.rml-shortcut-info-container').remove();
    
    // The normal media library view
    if (mediaSidebar.length > 0) {
        return $(html).appendTo(mediaSidebar);
    }else if (attachmentDetails.length > 0) {
        return $(html).insertAfter(attachmentDetails.children('.attachment-info').children('.settings'));
    }else{
        return container.replaceWithPush(html);
    }
};

/**
 * Create a WP RFC for a shortcut info container. 
 * 
 * @property {id} id The attachment id.
 * @function shortcutInfo
 * @listens module:util/hooks#wprfc/$function
 */
hooks.register('wprfc/shortcutInfo', async function({ id }) {
    const loadingContainer = appendTo($(this).addClass("rml-shortcut-info-container"), '<div style="height:50px;text-align:center;"><div class="spinner is-active" style="float: initial;margin: 0;"></div></div>'),
        { html } = await ajax('attachments/' + id + '/shortcutInfo');
    loadingContainer.replaceWithPush(html);
});