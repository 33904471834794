/** @module components/UploadMessage */
 
import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import { Progress } from './';
import { injectAndObserve } from '../store';
import { i18n } from '../util';
import { Icon } from 'react-aiot';

/**
 * Show the current uploading file with progress bar and status. It uses
 * the main store to read the current upload.
 * 
 * @type React.Element
 */
export default injectAndObserve(({ store }) => {
    const { currentUpload, uploadTotalRemainTime, readableUploadTotalLoaded, readableUploadTotalSize, readableUploadTotalBytesPerSec } = store;
    if (!currentUpload) {
        return null;
    }
    const { name, previewSrc, percent, node: { title, icon }, readableLoaded, readableSize, deny } = currentUpload,
        count = store.uploading.length;
    
    return (<span className="rml-upload">
        { !!previewSrc && (<div className="rml-upload-image">
            <img src={ previewSrc } />
        </div>)}
        <div className="rml-upload-container">
            <strong className="rml-upload-file">{ name }</strong>
            <div className="rml-upload-folder">{ icon } { title }</div>
            <Progress percent={ percent } size="small" status={ percent >= 100 ? 'success' : 'active' } />
            <div className="rml-upload-progress">
                { count > 1 && (<span>{ i18n('filesRemaining', { count }) } &middot;&nbsp;</span>) }
                { percent >= 100 ? i18n('receiveData') : (<span>{ readableLoaded } / { readableSize }</span>) }
                <br />
                { uploadTotalRemainTime } &middot; { readableUploadTotalBytesPerSec }/s&nbsp;
                { count > 1 ? (<span>&middot; { readableUploadTotalLoaded } / { readableUploadTotalSize }</span>) : '' }
            </div>
            { !!deny && (<div className="rml-upload-deny"><Icon type="warning" /> { deny }</div>) }
        </div>
    </span>);
});