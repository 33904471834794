/** @module components */

import React from 'react';
import ReactDOM from 'react-dom';
import Progress from 'antd/lib/progress';
import 'antd/lib/progress/style/index.css';

export {
    /**
     * @see https://ant.design/components/progress/
     * @returns React.Element
     * @function Progress
     */
    Progress
};

/**
 * Create a WordPress dash icon.
 * 
 * @property {string} name The icon
 * @see https://developer.wordpress.org/resource/dashicons/ Available icons
 * @returns React.Element
 * @function
 */
export const DashIcon = ({ name }) => <span className={ "dashicons dashicons-" + name } />;