/**
 * Create a WP RFC for a breadcrumb item and for a customField.
 * 
 * @property {string[]} data-path The pathes
 * @module others/rfcBreadcrumb
 */

import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import hooks from '../util/hooks';
import { ajax } from '../util';
import Breadcrumb from '../components/Breadcrumb';

/**
 * Create a WP RFC for a breadcrumb. All the element data is passed to
 * {@link module:components/Breadcrumb}.
 * 
 * @function breadcrumb
 * @listens module:util/hooks#wprfc/$function
 */
hooks.register('wprfc/breadcrumb', function(props) {
    ReactDOM.render(<Breadcrumb {...props} />, $(this).get(0));
});

/**
 * Create a WP RFC for a custom field. It puts a simple dropdown with folder
 * select to the element.
 * 
 * @property {string|int} selected The preselected id
 * @function customField
 * @listens module:util/hooks#wprfc/$function
 */
hooks.register('wprfc/customField', async function({ selected }) {
    const { html } = await ajax('tree/dropdown', { data: { selected } });
    $(this).html(html);
});