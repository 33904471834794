/**
 * Startup file which initializes the AIOT Tree. You can access the exports
 * through <code>window.rml</code>.
 * 
 * @module rml
 */
import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import { hooks, rmlOpts, i18n, urlParam, ajax, applyNodeDefaults, fetchTree, findDeep, humanFileSize,
    secondsFormat, dataUriToBlob, addUrlParam } from './util';
import optionsScreen from './others/optionsScreen';
import applyMediaViews, { firstCreatedToolbar } from './others/mediaViews';
import { anyKeyHolding } from './util/dragdrop';
import store, { injectAndObserve, TreeNode, Upload, StoredAppTree } from './store';
import './style/style.scss';
import 'react-aiot/src/style/theme-wordpress.scss';
import './hooks';
import './others/static';
import 'setimmediate'; // Polyfill for yielding

export {
    /**
     * @type module:util/hooks
     */
    hooks,
    /**
     * @type object
     */
    rmlOpts,
    /**
     * @type module:util.i18n
     */
    i18n,
    /**
     * @type module:util.urlParam
     */
    urlParam,
    /**
     * @type module:util.ajax
     */
    ajax,
    /**
     * @type module:util.applyNodeDefaults
     */
    applyNodeDefaults,
    /**
     * @type module:util.fetchTree
     */
    fetchTree,
    /**
     * @type module:util.findDeep
     */
    findDeep,
    /**
     * @type module:util.humanFileSize
     */
    humanFileSize,
    /**
     * @type module:util.secondsFormat
     */
    secondsFormat,
    /**
     * @type module:util.dataUriToBlob
     */
    dataUriToBlob,
    /**
     * @type module:util/addUrlParam
     */
    addUrlParam,
    /**
     * @type module:store.injectAndObserve
     */
    injectAndObserve,
    /**
     * @type module:store~store
     */
    store,
    /**
     * @type module:store/TreeNode~TreeNode
     */
    TreeNode,
    /**
     * @type module:store/Upload~Upload
     */
    Upload,
    /**
     * @type module:store.StoredAppTree
     */
    StoredAppTree
};

// ReplaceWith should return the new object
!$.fn.replaceWithPush && ($.fn.replaceWithPush = function(a) {
    const $a = $(a);
    this.replaceWith($a);
    return $a;
});

/**
 * General event when script for RML is ready to load.
 * 
 * @event module:util/hooks#general 
 */
hooks.call('general');

applyMediaViews();

$(document).ready(() => {
    // Add rml-touch class if touch device
    if ('ontouchstart' in window || window.navigator.maxTouchPoints) {
        $('body').addClass('rml-touch');
    }
    
    anyKeyHolding();
    'WebkitAppearance' in document.documentElement.style && $('body').addClass('rml-webkit');
    
    if (rmlOpts && $('body').hasClass('wp-admin') && $('body').hasClass('upload-php')) {
        let $container, container;
        const containerId = 'rml' + rmlOpts.blogId;
        
        /**
         * General event when DOM is ready and a list table / grid mode
         * is available in media library page.
         * 
         * @event module:util/hooks#ready
         */
        hooks.call('ready');
        
        // Create the container sidebar
        $('body').addClass('activate-aiot');
        $container = $('<div/>').prependTo('body.wp-admin #wpbody').addClass('rml-container');
        container = $container.get(0);
        
        // Create the wrapper and React component, the modal react element is created in hooks/modal.js
        if (rmlOpts.listMode === 'grid') {
            // When in grid mode, we have to wait for the first attachments browser
            firstCreatedToolbar.done(attachmentsBrowser => {
                ReactDOM.render(<StoredAppTree attachmentsBrowser={ attachmentsBrowser } id={ containerId }/>, container);
            });
        }else{
            ReactDOM.render(<StoredAppTree id={ containerId }/>, container);
        }
    }
    
    // Wait for modals
    hooks.register('attachmentsBrowser/modal/dom/ready', function(container) {
        if (!rmlOpts)
            return;
        try {
            hooks.call('attachmentsBrowser/modal/exception', [container], this);
            const containerId = 'rml' + rmlOpts.blogId;
            ReactDOM.render(<StoredAppTree attachmentsBrowser={ this } isModal id={ containerId }/>, container.el);
        }catch(e) {
            // Silence is golden.
        }
    });
    
    // Options panel
    $('body').hasClass('options-media-php') && optionsScreen();
});