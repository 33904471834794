/** @module others/filter */

import store from '../store';
import $ from 'jquery';
import _ from '_';

/**
 * The attachments filter selectors.
 */
export const FILTER_SELECTOR = '.attachment-filters.attachment-filters-rml';

/**
 * The filter select dropdown prepared as backbone object.
 */
export const filter = {
    id: 'media-attachment-filters-rml',
    className: 'attachment-filters attachment-filters-rml',
    lastSlugs: {},
    
    createFilters: function(namesSlug) {
        this.$el.data('backboneView', this);
        
        // default "all" filter, shows all tags
		const filters = this.filters = {
			all: {
				text: 'All',
				props: {
					rml_folder: ''
				},
				priority: 10
			}
		};
		
        // No filters loaded, yet
        if (namesSlug) {
            this.lastSlugs = namesSlug;
        	const { names, slugs } = namesSlug;
			
			// create a filter for each tag
			let props, node;
			for (let i = 0; i < names.length; i++) {
				node = store.getTreeItemById(slugs[i]);
			    props = {
					rml_folder: slugs[i]
				};
				
				// Add order by
				if (node && node.contentCustomOrder === 1) {
					props.orderby = 'rml';
					props.order = 'ASC';
				}
				
				filters[slugs[i]] = {
					text: names[i],
					props,
					priority: 20 + i
				};
			}
        }
        
    	//this.model.set(filters['all'].props); // Implemented in mediaViews
    	
    	if (namesSlug) {
    	    // Build `<option>` elements.
    		this.$el.html(_.chain(this.filters).map((filter, value) => ({
				el: $('<option></option>').val( value ).html(filter.text)[0],
				priority: filter.priority || 50
			}), this).sortBy('priority').pluck('el').value());
    		
    		// Reselect
    		this.select();
    	}
    }
};