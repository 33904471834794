/** @module others/renderOrderMenu */

import React from 'react';
import ReactDOM from 'react-dom';
import { Menu, message } from 'react-aiot';
import { i18n } from '../util';
import { applyToAttachmentsBrowser } from '../hooks/sortable';

const { Item, SubMenu, Divider } = Menu;

/**
 * An element rendering sortables for the popup menu.
 * 
 * @returns React.Element[]
 */
const createSortables = (sortables, select, selectText) => Object.keys(sortables).map(key => (
    <Item key={ key }>{ sortables[key] } { select === key && (<strong>({ selectText })</strong>) }</Item>
) );

/**
 * Apply an order to a tree node object and afterwards reload the view.
 * 
 * @this AppTree
 */
async function applyOrder(selected, key, automatically) {
    const hide = message.loading(i18n('orderLoadingText', { name: selected.title })),
        { attachmentsBrowser } = this;
    await selected.applyOrder(key, automatically);
    
    // Apply props to backbone model
    applyToAttachmentsBrowser(attachmentsBrowser, selected);
    
    this.handleReload();
    hide();
}

/**
 * When clicking on a menu item in the order menu popup.
 * 
 * @this AppTree
 */
async function handleClick({ key, keyPath }) {
    const path = keyPath.reverse(),
        { selected } = this.props.store,
        _applyOrder = applyOrder.bind(this);
    if (path[0] === 'applyOnce') { // Apply sorting once
        _applyOrder(selected, key);
    }else if (path[0] === 'applyAutomatically') {
        _applyOrder(selected, key, true);
    }else if (key === 'reset') {
        _applyOrder(selected, 'original');
    }else if (key === 'resetAutomatically') {
        _applyOrder(selected, 'deactivate');
    }else if (key === 'applyReindex') {
        _applyOrder(selected, 'reindex');
    }else if (key === 'applyResetLast') {
        _applyOrder(selected, 'last');
    }
}

/**
 * Render the order menu.
 * 
 * @type React.Element
 */
export default function() {
    const { store } = this.props,
        { selected, sortables } = store,
        isSortable = selected && selected.properties && selected.contentCustomOrder !== 2;

    return <Menu onClick={ handleClick.bind(this) }>
        <Item key="reset" disabled={ !isSortable || selected.contentCustomOrder === 0 }>
            { i18n('resetOrder') }
        </Item>
        { isSortable && (<SubMenu key="applyOnce" title={ i18n('applyOrderOnce') } disabled={ selected.orderAutomatically }>
            { sortables && createSortables(sortables, selected.lastOrderBy, i18n('last')) }
        </SubMenu>) }
        { isSortable && (<Divider />) }
        { isSortable && selected.orderAutomatically && (<Item key="resetAutomatically" disabled={ !isSortable || selected.contentCustomOrder === 0 }>
            { i18n('deactivateOrderAutomatically') }
        </Item>) }
        { isSortable && (<SubMenu key="applyAutomatically" title={ i18n('applyOrderAutomatically') }>
            { sortables && createSortables(sortables, selected.orderAutomatically && selected.lastOrderBy, i18n('latest')) }
        </SubMenu>) }
        { isSortable && selected.contentCustomOrder === 1 && (<Divider />) }
        { isSortable && selected.contentCustomOrder === 1 && (<Item key="applyReindex">
            { i18n('reindexOrder') }
        </Item>) }
        { isSortable && selected.contentCustomOrder === 1 && (<Item key="applyResetLast">
            { i18n('resetToLastOrder') }
        </Item>) }
    </Menu>;
}