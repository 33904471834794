/** @module hooks/shortcut */

import React from 'react';
import ReactDOM from 'react-dom';
import { hooks, i18n } from '../util';
import $ from 'jquery';
import { Tooltip } from 'react-aiot';

/**
 * A icon with tooltip showing a text that the given attachment is a shortcut.
 * 
 * @param {object} props Properties
 * @type React.Element
 */
const ShortcutIconTooltip = props => (<Tooltip placement="top" title={ i18n('shortcut') }
    content={ i18n('shortcutInfo') }>
    <i className="rmlicon-share" />
</Tooltip>);

/**
 * Is called after each grid item in the attachments browser is rendered completely
 * and adds the shortcut icon container with a tooltip.
 */
hooks.register('attachmentsBrowser/item/rendered', function($el, model, element) {
    // Parse it
    const isShortcut = model.attributes.rmlIsShortcut > 0;
    let icon = $el.children('.attachment-preview').children('.rml-shortcut-container');
    icon.remove();
    
	if (isShortcut > 0) {
    	$el.addClass('rml-shortcut rml-shortcut-grid');
    	icon = $('<div class="rml-shortcut-container"></div>').appendTo($el.children('.attachment-preview'));
    	ReactDOM.render(<ShortcutIconTooltip />, icon.get(0));
	}else{
	    $el.removeClass('rml-shortcut rml-shortcut-grid');
	}
});

/**
 * Is called in media library list mode and shows a shortcut icon to each shortcut attachment.
 */
hooks.register('ready', function() {
    $('.rmlShortcutSpan').each(function() {
        const tr = $(this).parents('tr'),
            imgContainer = tr.children('td.title').find('.media-icon'),
            icon = $('<div class="rml-shortcut-container"></div>').appendTo(imgContainer);
        ReactDOM.render(<ShortcutIconTooltip />, icon.get(0));
    });
});

/**
 * Is called in grid mode when an item is removed. This callback handles the remove of duplicate
 * shortcuts when one gets removed.
 */
hooks.register('attachmentsBrowser/item/removed', (element, model, collection) => {
    const { id } = model.attributes;
    collection.models.forEach(single => {
        if (single.attributes.rmlIsShortcut === id) {
            collection.remove(single);
        }
    });
});