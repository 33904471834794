/** @module others/optionsScreen */

import $ from 'jquery';
import { ajax, rmlOpts, hooks } from '../util';

/**
 * Do the options screen with a nav bar (WordPress standard). This
 * nav bar is not ReactJS.
 */
export default function() {
    // Append to known option
    const container = $('<div class="rml-options"><nav><ul></ul></nav></div>').insertBefore($('[for="rml_load_frontend"]').parents('table').prev()),
        headline = $('<h2>Real Media Library</h2>').insertBefore(container),
        nav = container.find('nav ul');
    let navLiCnt = 0;
    $('<a target="_blank" href="https://matthias-web.com/wordpress/real-media-library/add-ons/"><strong>Browse Add-Ons</strong></a>').insertAfter(headline);
                        
    // Search the option panels
    $('table.form-table').each(function() {
        const oHeadline = $(this).prev();
        let sHeadline = oHeadline.html();
        if (sHeadline && sHeadline.indexOf('RealMediaLibrary') === 0) {
            sHeadline = sHeadline.split(":", 2)[1];
            
            // Append headline to options panel
            const li = $('<li class="nav-tab ' + ((navLiCnt === 0) ? 'nav-tab-active' : '') + '">' + sHeadline + '</li>').appendTo(nav),
                section = $(this).appendTo(container);
                
            !navLiCnt && section.show();
            li.click(function() {
                container.children('table').hide();
                nav.find('.nav-tab-active').removeClass('nav-tab-active');
                $(this).addClass('nav-tab-active');
                section.show(); 
            });
            
            oHeadline.remove();
            navLiCnt++;
        }
    });
}

/**
 * When a .rml-rest-button is pressed show a loading indicator and send
 * the request to the REST server.
 */
$(() => $(document).on('click', '.rml-rest-button', function(e) {
    let { url, method, ...data } = $(this).data(), btn = $(this);
    if (window.confirm(rmlOpts.lang.areYouSure)) {
        btn.html('<div class="spinner is-active" style="float: initial;margin: 0;"></div>');
        btn.attr('disabled', 'disabled');
        
        /**
         * Fired when a button with class .rml-rest-button gets clicked and
         * the POST data is prepared so you can modify it. The $url is the
         * data-url attribute of the button. You also have to define a
         * data-method attribute.
         * 
         * @event module:util/hooks#rest/button/prepare/$url
         * @param {object} data The data
         * @this jQuery
         */
        hooks.call('rest/button/prepare/' + url, [ data ], btn);
        ajax(url, { method, data }).then((...args) => {
            btn.html('<i class="fa fa-check"></i> ' + rmlOpts.lang.success).attr("disabled", false);
            
            /**
             * Fired when a button with class .rml-rest-button is successfully saved.
             * 
             * @event module:util/hooks#rest/button/success/$url
             * @param {mixed} args... The $.ajax success arguments
             * @param {string} method The method
             * @param {object} data The data
             * @this jQuery
             */
            hooks.call('rest/button/success/' + url, [ ...args, method, data ], btn);
        }, (...args) => {
            btn.html('<i class="fa fa-warning"></i> ' + rmlOpts.lang.failed).attr("disabled", false);
            
            /**
             * Fired when a button with class .rml-rest-button is successfully saved.
             * 
             * @event module:util/hooks#rest/button/error/$url
             * @param {mixed} args... The $.ajax success arguments
             * @param {string} method The method
             * @param {object} data The data
             * @this jQuery
             */
            hooks.call('rest/button/error/' + url, [ ...args, method, data ], btn);
        });
    }
    e.preventDefault();
    return false;
}));

hooks.register('rest/button/success/export', (response) => {
    $('#rml_export_data textarea').get(0).value = response;
});

hooks.register('rest/button/prepare/import', (data) => {
    data.import = encodeURIComponent($("#rml_import_data textarea").get(0).value);
});