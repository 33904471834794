/** @module hooks/permissions */

import { hooks, i18n } from '../util';
import produce from 'immer';
import rmlOpts from 'rmlopts';

/**
 * This functions generates a tooltip text for the locked toolbar button.
 * 
 * @params {object} props The properties
 * @params {string[]} props.restrictions The restrictions
 * @returns {React.Element}
 */
export const LockedToolTipText = ({ restrictions }) => {
    let inheritsCount = 0;
    return <div>
        { i18n('restrictionsSuffix') }<br />
        { restrictions.map(r => {
            const inherits = r.slice(-1) === '>',
                i18nKey = inherits ? r.slice(0, -1) : r;
            inherits && inheritsCount++;
            
            return <div key={ r }>
                - { i18n('restrictions.' + i18nKey) }
                { inherits && (<strong> *</strong>) }
            </div>;
        }) }
        { inheritsCount > 0 && (<div><strong>*</strong> { i18n('restrictionsInherits') }</div>) }
    </div>;
};

/**
 * Show a locked button with tooltip in toolbar depending the permissions.
 * 
 * @param {module:AppTree} element The AppTree
 * @param {object} [selected=Current] The node
 */
export default (element, selected = element.getTreeItemById()) => {
    let restrictions = [];
    const { currentFolderRestrictions, toolbar_locked } = element.state,
        lockedVisible = !!(selected && (restrictions = selected.properties.restrictions).length),
        lockedToolTipText = lockedVisible ? <LockedToolTipText restrictions={ restrictions } /> : '';
    currentFolderRestrictions.join() !== restrictions.join() && element.setState({
        currentFolderRestrictions: restrictions || [],
        toolbar_locked: produce(toolbar_locked, draft => {
            draft.visible = lockedVisible;
            draft.toolTipText = lockedToolTipText;
        })
    });
};

/**
 * A new node is pushed to the folder tree. We can modify it here that way that
 * a locked icon is shown.
 */
hooks.register('tree/node', node => {
    const { restrictions } = node.properties;
    if (restrictions.length) {
        node.className['rml-locked'] = true;
    }
});

/**
 * When a file is added do check if upload is allowed to this folder.
 */
hooks.register('uploader/add', function(file, { properties }, store) {
    if (properties && properties.restrictions && properties.restrictions.join().indexOf('ins') > -1) {
        this.node = store.getTreeItemById(+rmlOpts.rootId, false);
        this.deny = i18n('restrictions.ins');
    }
});